$(function() {
  'use strict';

  $('.js-slider').slick({
    slidesToShow: 1,
    autoplay: true,
    arrows: true,
    dots: true,
    dotsClass: "c-slider__dots",
    infinite: true,
    pauseOnHover: false
  });

});
